.error-container {
    text-align: center;
}

.error-container img {
    width: 40%;
}

@media screen and (max-width: 600px) {
    .error-container img {
        width: 100%;
    }
}