.assurance-container {
    background-image: url(../../assets/images/wyg-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.top-text {
    font-size: 31px;
    font-weight: 200;
}

.top-text span:last-child {
    font-weight: 800;
    color: var(--primary-color);
}

.assured-containers {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
}


.assurance-icon {
    font-size: 25px;
}

.assurance-text {
    font-size: 21px;
    margin-top: 10px;
}

.fa-hand-holding-heart {
    color: #418441;
    background-color: #94e194;
    padding: 15px;
    border-radius: 50%;
}

.fa-brain {
    color: #DD9305;
    background-color: #FFD482;
    padding: 15px;
    border-radius: 50%;
}

.fa-people-arrows {
    color: #900808;
    background-color: #ECBBBB;
    padding: 15px;
    border-radius: 50%;
}

.fa-screwdriver-wrench {
    color: #07609F;
    background-color: #BAF0FC;
    padding: 15px;
    border-radius: 50%;
}


/* MEDIA QUERY */

@media screen and (max-width: 1100px) {
    .assured-containers {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3rem;
    }
}

@media screen and (max-width: 900px) {
    .assured-containers {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem;
    }
}

@media screen and (max-width: 600px) {
    .assurance-container {
        text-align: center;
    }

    .top-text {
        font-size: 21px;
    }

    .assured-containers {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 3rem;
    }
}