.pricing-lists {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.pricing-lists .card {
  background: white;
  transition: var(--transition);
}

.pricing-lists .card:hover {
  box-shadow: var(--shadow);
  padding: 2rem !important;
  transition: var(--transition);
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  gap: 10px;
}

.card-title p {
  font-size: 18px;
}

.card-title h1 span {
  font-size: 21px;
}

.card-title h1 {
  font-size: 20px;
}

.card-benefit-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  width: 100%;
  height: 100%;
}

.card-benefit ul {
  padding: 20px 0;
  font-family: "Sen", sans-serif;
}

.card-benefit ul li {
  padding: 8px 0;
}

.cancel-benefit {
  text-decoration: line-through;
}

.enrol-btn-list {
  background: linear-gradient(89.95deg, #460101 41.44%, #db1818 99.74%);
  width: 100%;
  margin: auto;
}

.enrol-btn-list:hover {
  background: linear-gradient(89.95deg, #db1818 20.18%, #460101 106.99%);
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .pricing-lists {
    gap: 1rem;
  }

  .card-title p {
    font-size: 17px;
  }

  .card-title h1 {
    font-size: 20px;
  }

}

@media screen and (max-width: 1100px) {
  .pricing-lists {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }

  .card-title p {
    font-size: 16px;
  }

  .card-title h1 {
    font-size: 16px;
  }

  .card-title h1 span {
    font-size: 17px;
  }
}

@media screen and (max-width: 800px) {
  .pricing-lists {
    gap: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .pricing-lists {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
}
