.redirect-container {
    text-align: center;
    padding: 2rem 200px;
    width: 70%;
    margin: auto;
}

.redirect-container img {
    width: 350px;
}

.redirect-container button {
    width: 300px;
}

.redirect-container button:hover {
    width: 400px;
}

@media screen and (max-width: 1440px) {
    .redirect-container {
        padding: 2rem 100px;
    }
  }

  @media screen and (max-width: 900px) {
    .redirect-container {
        width: 100%;
    }
  }
  
  @media screen and (max-width: 700px) {
    .redirect-container {
        padding: 2rem 50px;
        width: 100%;
    }
  }
  
  
  @media screen and (max-width: 420px) {
    .redirect-container {
        padding: 2rem 20px;
    }

    .redirect-container img {
        width: 300px;
    }
  }