.ab-hero-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(103.52deg, rgba(63, 168, 244, 0.25) -81.39%, #cfecff 4.38%, #FEFEFE 53.82%, rgba(131, 183, 217, 0) 174.52%);
}

.a-hero-info {
    width: 60%;
}

.hero-img {
    width: 30%;
}

.hero-img img {
    width: 350px;
}


@media screen and (max-width: 1200px) {
    .a-hero-info {
        width: 65%;
    }

    .hero-img img {
        width: 300px;
    }
    
}

@media screen and (max-width: 995px) {
    .ab-hero-container {
        flex-direction: column;
        gap: 3rem;
        text-align: center;
        padding-top: 10rem;
    }

    .a-hero-info,.hero-img {
        width: 100%;
    }
    
}