.faq-container {
    background-image: url(../../assets/images/faq-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.faq-container h2 {
    color: var(--white-color);
}

.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
    border-bottom: 1px solid #e0e0e0;
}

/* MEDIA QUERIES */

@media screen and (max-width: 600px) {
    .faq-container {
        padding: 0 20px;
    }
}