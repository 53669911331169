.container {
  background-color: white;
  padding: 20px;
  padding-top: 30px;
  padding-right: 30px;
  position: fixed;
  bottom: 0;
  width: max-content;
  max-width: 100vw;
  z-index: 9999;
  border-radius: 0px 10px 0px 0px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}

.container.visible {
  left: 0;
  transition: all;
  transition-duration: 500ms;
}

.container.hidden {
  left: -100vw;
  transition: all;
  transition-duration: 500ms;
}

.flag-container {
  border: 0.1px solid rgb(27, 27, 27);
  width: max-content;
  display: flex;
  justify-self: center;
  margin-top: 10px;
  position: relative;
  width: 39px;
  height: 30px;
  overflow: hidden;
}

.text-one {
  text-align: center;
}

.text-two {
  text-align: center;
  margin-top: 10px;
}

.close-button {
  position: absolute;
  top: 0;
  right: 5px;
  border: none;
  font-size: 22px;
  background-color: transparent;
}
