

.new-divider {
    width: 90%;
    margin: auto;
}

.about-container {
    width: 100%;
}

/* MEDIA QUERY */
@media screen and (max-width: 1100px) {
    .video-container iframe {
        width: 100%;
    }
  }
  
  @media screen and (max-width: 700px) {
    .custom-container {
        padding: 7rem 50px;
    }
  }

  @media screen and (max-width: 500px) {
    .custom-container {
        padding: 7rem 20px;
    }
  }
  