.client-container {
  background-image: url(../../assets/images/our-client-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.clients {
  display: flex;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
}

/* Swiperslide css custom styles */
.swiper-slide {
  width: 10%;
}

.swiper-3d .swiper-slide-shadow-left {
  background-image: unset;
}
