.submain-container {
    /* text-align: center; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.submain-container h1, .video-container {
    width: 50%;
}

@media screen and (max-width: 950px) {
    .submain-container {
        flex-direction: column;
        text-align: center;
    }

    .submain-container h1, .video-container {
        width: 100%;
    }

    iframe {
        height: 450px;
    }


}