.flex-boxes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;
    margin-bottom: 100px;
}

.off {
    display: none;
}

.dj, .recording, .speaker {
    margin-top: 20rem;
}

.detail-img {
    width: 500px;
    transition: var(--transition);
}

.detail-img:hover {
    width: 510px;
}

@media screen and (max-width: 1200px) {
    .detail-img {
        width: 400px;
        transition: var(--transition);
    }

    .detail-img:hover {
        width: 405px;
    }
}

@media screen and (max-width: 995px) {
    .flex-boxes {
        flex-direction: column;
        text-align: center;
    }

    .detail-img {
        width: 100%;
        transition: var(--transition);
    }

    .detail-img:hover {
        width: 100%;
    }

    .dj, .recording, .speaker {
        margin-top: 10rem;
    }

    .off {
        display: block;
    }
    
}