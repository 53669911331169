.select-selected {
    background-color: rgb(43, 43, 43);
    padding: 5px 20px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.modal-divide {
    background: rgb(222, 222, 222);
}


@media screen and (max-width: 450px) {
    .modal-header .fs-5 {
        font-size: 15px !important;
    }
    .modal-body .fs-5 {
        font-size: 18px !important;
    }
}