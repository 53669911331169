.hte-container {
    background: linear-gradient(103.52deg, rgba(63, 168, 244, 0.25) -81.39%, #FEFEFE 4.38%, #ebfaff 53.82%, rgba(131, 183, 217, 0) 174.52%);
}

.hte-text {
    width: 750px;
    font-size: 31px;
}

.hte-small-text {
    font-size: 20px;
}

.how-to-steps {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    margin-top: 4rem;
}

.how-to-steps .num {
    font-size: 20px;
    margin-bottom: 10px;
}


@media screen and (max-width: 1250px) {
    .how-to-steps {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 800px) {
    .how-to-steps {
        grid-template-columns: repeat(2, 1fr);
    }

    .hte-text {
        width: 500px;
        font-size: 27px;
    }
}

@media screen and (max-width: 600px) {
    .hte-text {
        width: 400px;
        font-size: 22px;
    }
}

@media screen and (max-width: 500px) {
    .hte-container {
        text-align: center;
    }

    .hte-text {
        width: unset;
        font-size: 22px;
    }

    .how-to-steps {
        grid-template-columns: repeat(1, 1fr);
    }
}