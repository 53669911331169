.sa-card {
  background: linear-gradient(89.95deg, #460101 41.44%, #db1818 99.74%);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  color: white;
}

.sa-card h4 {
  color: white;
  width: 50%;
}

.sa-card p {
  color: white;
}

.standalone-contents {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.sa-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.sa-enrol-btn {
  background-color: white;
  color: #3c3c3c;
  padding: 10px;
  cursor: pointer;
  width: 30%;
}

@media screen and (max-width: 950px) {
  .standalone-contents {
    grid-template-columns: repeat(1, 1fr);
  }

  .sa-enrol-btn {
    width: 50%;
  }
}
