.courses-container {
    background-image: url(../../assets/images/course-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding: 200px;
}

.co-enrol-btn {
    width: 200px;
}

.co-enrol-btn:hover {
    width: 250px;
}


@media screen and (max-width: 700px) {
    .courses-container {
        padding: 200px 100px;
    }
}

@media screen and (max-width: 450px) {
    .courses-container {
        padding: 200px 30px;
    }
}