.main {
    background-image: url(../../assets/images/hero-section-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }
  
  .main-contents {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
  }
  
  .lead-texts {
    width: 50%;
  }
  
  .hero-img-container img {
    width: 300px;
  }
  
  .lead-texts h1 {
    font-size: 3rem;
    font-weight: 200;
    color: black;
  }
  
  .lead-texts span {
    color: var(--primary-color);
    font-size: 3rem;
    font-weight: 700;
    line-height: 2rem;
  }
  
  
  
  /* MEDIA QUERY */
  @media screen and (max-width: 1200px) {
    .hero-img-container img {
      width: 350px;
    }
  
    .lead-texts h1, .lead-texts span {
      font-size: 2rem;
    }
  
    .lead-texts p {
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 990px) {
    .main-contents {
      flex-direction: column;
      text-align: center;
    }
  
    .lead-texts {
      width: 100%;
    }
  
    .lead-texts h1, .lead-texts span {
      font-size: 2.8rem;
    }
  
    .lead-texts p {
      font-size: 16px;
    }
  }
  
  @media screen and (max-width: 420px) {
    .hero-img-container img {
      width: 300px;
    }
  
    .lead-texts p {
      font-size: 13px;
    }
  }
  
  
  