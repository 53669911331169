.policy-container {
    padding: 50px;
    background-color: white;
    border: 1px solid rgb(239, 239, 239);
    margin-top: 2rem;
    border-radius: 10px;
}

.inner-lists {
    margin-top: 20px;
}

.policy-container ol li {
    list-style: unset;
}

.p-title {
    margin-top: 4rem;
}

@media screen and (max-width: 800px) {
    .policy-container {
        padding: 20px;
    }
}

@media screen and (max-width: 500px) {
    .policy-container {
        padding: 10px;
    }
}

@media screen and (max-width: 450px) {
    .policy-container {
        padding: 5px;
    }
}