.fc {
    padding: 70px 200px;
    background: var(--bg-footer);
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
}

.footer-container a {
    color: var(--text-color);
}

.footer-container a:hover {
    color: var(--primary-color);
}

.footer-container .footer-copyrights {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.footer-social-links {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.footer-links ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 3rem;
    padding-left: 0;
}

.footer-links ul a {
    color: var(--text-color);
}

/* MEDIA QUERY */
@media screen and (max-width: 1440px) {
    .fc {
        padding: 30px 100px;
    }
  }
  
  @media screen and (max-width: 700px) {
    .fc {
        padding: 30px 50px;
    }

    .footer-container {
        flex-direction: column;
        gap: 2rem;
    }
  }

  @media screen and (max-width: 500px) {

    .footer-links ul {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3rem;
    }
  }

  
  @media screen and (max-width: 420px) {
    .fc {
        padding: 30px 20px;
    }

    .footer-links ul {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3rem;
    }
  }