.about-desc {
    background: linear-gradient(89.95deg, #460101 41.44%, #DB1818 99.74%);
    padding: 150px;
    text-align: center;
    border-radius: 32px;
}

.about-desc p {
    color: white;
    font-weight: 400;
    line-height: 2;
    font-size: 21px;
}

.ln-mr-btn {
    background-color: var(--bg-footer);
    margin-top: 50px;
    width: 200px;
}

.ln-mr-btn:hover {
    background-color: black;
    width: 220px;
}


/* MEDIA QUERY */
@media screen and (max-width: 1100px) {
    .about-desc {
        padding: 100px;
    }
}

@media screen and (max-width: 750px) {
    .about-desc {
        padding: 80px;
    }

    .about-desc p {
        font-size: 15px;
    }

    .ln-mr-btn {
        width: 150px;
        font-size: 14px;
    }
}

@media screen and (max-width: 600px) {
    .about-desc {
        padding: 80px 50px;
    }
}

@media screen and (max-width: 450px) {
    .about-desc {
        padding: 50px;
    }

    .ln-mr-btn {
        margin-top: 20px;
        width: 200px;
    }
}