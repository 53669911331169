.get-started {
    background: conic-gradient(from 146.07deg at 80% 64.18%, #292929 0deg, #131313 181.87deg, #434343 360deg);
    padding: 100px;
    border-radius: 20px;
    color: white;
}

.get-started p {
    font-size: 18px;
    color: white;
}

.gt-action-btns {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
}

.gt-enrol-btn {
    color: black;
    background: white;
    width: 150px;
}

.gt-enrol-btn:hover {
    width: 200px;
}

.gt-contact-us {
    background: transparent !important;
}


@media screen and (max-width:900px) {
    .get-started {
        padding: 80px;
    }
}

@media screen and (max-width:700px) {
    .get-started {
        padding: 50px;
    }
}

@media screen and (max-width:500px) {
    .get-started {
        text-align: center;
        padding: 30px;
    }

    .get-started p {
        font-size: 14px;
        color: white;
    }

    .gt-enrol-btn {
        width: 100%;
    }

    .gt-action-btns {
        flex-direction: column;
    }
}