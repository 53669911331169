* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background: var(--bg-color);
  color: var(--text-color);
}

:root {
  --bg-color: #fff;
  --bg-color2: #fff;
  --primary-color: #d30c0c;
  --fade-primary-color: #dc6a6a;
  --secondary-color: #fff;
  --text-color: #5b5b5b;
  --bg-footer: #171717;
  --transition: 0.5s ease-in-out;
  --shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}

p {
  color: var(--text-color);
  margin-bottom: 0;
  font-family: 'Sen', sans-serif;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.custom-container {
  width: 100%;
  padding: 5rem 200px;
}

.divider {
  height: 1px;
  width: 100%;
  background: var(--text-color);
}

.button {
  border: none;
  color: var(--secondary-color);
  font-weight: 500;
  padding: 15px;
  transition: var(--transition);
  width: max-content;
  border-radius: 5px;
}

.primary-btn {
  background: var(--primary-color);
}

.primary-btn:hover {
  background: var(--fade-primary-color);
}

.secondary-btn {
  background: var(--bg-color);
}

.secondary-btn:hover {
  background: var(--text-color);
}

.textfield {
  background: transparent;
  border: none;
  border-radius: 0;
  outline: 0;
  border-bottom: 1px solid var(--text-color);
  color: var(--text-color);
  padding: 10px 0;
}

::placeholder {
  color: var(--text-color);
}




/* MEDIA QUERY */
@media screen and (max-width: 1440px) {
  .custom-container {
      padding: 7rem 100px;
  }
}

@media screen and (max-width: 700px) {
  .custom-container {
      padding: 10rem 50px;
  }
}


@media screen and (max-width: 420px) {
  .custom-container {
      padding: 8rem 20px;
  }
}
