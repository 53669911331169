nav {
    width: 100%;
    background: transparent;
    position: fixed;
    z-index: 1000;
    color: var(--text-color);
}

.navigation {
    padding: 30px 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.kma-logo {
    width: 50px;
}

.nav-scroll {
    background-color: var(--bg-color);
    box-shadow: var(--shadow);
    padding: 0;
}

.nav-links {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    gap: 4rem;
}

.enrol-btn {
    width: 150px;
}

.navigation .nav-links a {
    color: var(--text-color);
    font-size: 16px;
    font-family: 'Sen', sans-serif;
}

.navigation .nav-links a:hover {
    color: var(--fade-primary-color);
}

.toggle-nav-button {
    display: none;
}


/* MEDIA QUERY */
@media screen and (max-width: 1440px) {
    .navigation {
        padding: 15px 100px;
    }
}

@media screen and (max-width: 990px) {
    .nav-links {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: fixed;
        top: 0;
        left: -100%;
        width: 250px;
        height: 100vh;
        background: var(--bg-color);
        box-shadow: var(--shadow);
        transition: var(--transition);
        padding-top: 7rem;
        z-index: 200;
    }

    .nav-links.open {
        left: 0;
    }

    .toggle-nav-button {
        display: block;
    }
}

@media screen and (max-width: 700px) {
    .navigation {
        padding: 15px 50px;
    }
}


@media screen and (max-width: 420px) {
    .navigation {
        padding: 15px 20px;
    }
}